@font-face {
    font-family: 'Proxima Nova Bold';
    src: local('Proxima Nova Bold'), url(./assets/fonts/proximanova-bold.woff) format('woff');
}


@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova'), url(./assets/fonts/proximanova-light.woff) format('woff');
}

@font-face {
    font-family: 'Proxima Regular';
    src: local('Proxima Regular'), url(./assets/fonts/proximanova-regular.ttf) format('woff');
}
@font-face {
    font-family: 'DMSans-Regular';
    src: local('DMSans-Regular'), url(./assets/fonts/DMSans-Regular.ttf) format('woff');
}

body,
* {
    margin: 0;
    font-size: 16px;
    font-family: "Proxima Nova Bold", Helvetica, Arial, sans-serif;
}

div.bbb-widget {
    -webkit-overflow-scrolling: touch
}

.valueGuideSelect {
    width: 100% !important;
}

.Select-placeholder {
    font-weight: 400;
    color: rgb(85, 85, 85) !important;
}

body {
    transform: translate(0);
}
